class StringOps{
    /**
     * truncateWithEllipsis(...)
     * input => "This is so nice", 4
     * output => "This..."
     * @param {string} str 
     * @param {number} charCount 
     */
    static truncateWithEllipsis(str, charCount){
        if(typeof(str) == typeof('') && typeof(charCount) == typeof(0) && charCount > 0){
            return (str.length <= charCount) ? str : str.slice(0, charCount).trim() + "...";
        }
        else return null;
    }

    /**
     * pad(..)
     * input = "11", 4
     * output = "0011"
     * @param {string} n = the string to pad
     * @param {number} width = total length desired after padding
     * @param {*} z = character to pad with
     */
    static pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    /**
     * getProp(obj, str)
     * - obj {Object} - the base object
     * - str {string} - the string representation of the accessor
     * extracts a property deep inside an object without throwing an error
     * usage: getProp(obj, 'prop1.prop2[0].item') => obj.prop1.prop2[0].item
     */
    static getProp(obj, stringPath) {
      
        if (!stringPath || typeof (stringPath) != typeof ('') || stringPath.trim().length == 0) return null;

        let curObj = obj;
        let pathArr = stringPath.match(/([^\.\[\]]+)/g);

        for (let p in pathArr) {
            if (curObj === undefined || curObj === null) return null;
            curObj = curObj[pathArr[p]];
        }

        return (curObj === undefined || curObj === null || curObj.length === 0) ? null : curObj;
    }

    /**
     * replaceAll(src, search, replace)
     * Replace all occurrences of 'search' with 'replace' in the 'src' string
     * @param {string} s - the source string
     * @param {string} search - the string to find
     * @param {string} replacement - the string to replace all occurrences of 'search' with
     */
    static replaceAll(s, search, replacement){
        if(!s || typeof(s) != typeof('') || s.length == 0) return s;
        return s.split(search).join(replacement);
    }

    /**
     * Given a string of format "2016-08-16T04:00:00.000Z", return "2016-08-16"
     * @param {string} str 
     */
    static clipDateFromLongDateString(s){
        if(!s || typeof(s) != typeof('') || s.length == 0) return s;
        let indexT = s.indexOf("T");
        if(indexT >= 0){
            s = s.substring(0,indexT);
        }

        return s;
    }

    /**
     * Capitalize the specified name. Even handles names like McDonald, where the first letter after
     * "Mc" needs to be capital
     * @param {string} s - usually a name
     */
    static capitalize(s){
        if(!s || typeof(s) != typeof('') || s.length == 0) return s;
        let parts = s.trim().toLocaleLowerCase().split(' ');
        for(let i = 0 ; i < parts.length; i++){
            parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
            if(parts[i].indexOf("Mc") == 0) parts[i] = "Mc" + parts[i].charAt(2).toUpperCase() + parts[i].slice(3);
        }

        return parts.join(' ');
    }

    static removeQuotes(s){
        if(!s || typeof(s) != typeof('') || s.length == 0) return s;

        s = this.replaceAll(s, "'", "");
        s = this.replaceAll(s, '"', "");
        return s;
    }

    static uniqueId() {
        return Math.random().toString(36).substr(2, 16);
    }
      
}

module.exports = StringOps;