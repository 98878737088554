let _stringOps = require("../../custom_modules/string-ops");

class JsModule{

    /***************************************************************************
     * standard event debounce function
    ***************************************************************************/ 
    static debounce(func, millis=300, args) {
        return function(event){
            let _self = this;
            if(typeof(func) == 'function' && !_self.isRunning){
                _self.isRunning = true;
                func(event, args);
                var timer = setTimeout(() => _self.isRunning = false, millis);
            }
        }
    }

    /***************************************************************************
     * isDev - check if we're running a dev / qa build of the site
    ***************************************************************************/ 
    static isDev() {
        let result = false;
        switch(window.env.trim().toLowerCase()){
            case 'dev':
            case 'qa':
                result = true;
                break;
            
            default:
                break;
        }

        return result;
    }

     /**
     *  validateProp()
     *  Validates if a prop from the returned data is valid and returns a dom element
     *  @param {Object} item 
     *  @param {String} propName 
     */ 
    static validateProp(item, propName, domSettings, transform) {
        let val = _stringOps.getProp(item, propName);
        let el = '';
        let { tag, className } = domSettings;

        if(transform != undefined && val != null) val = transform(val);

        if(val != null){
            let src = tag == 'img' ? `src="${val}"/>` : '>';
            let endingTag = tag != 'img' ? `</${tag}>` : ''
            let classAttr = className != '' ? `class="${className}"`: '';
            let value = tag != 'img' ? val : '';
            el = `<${tag} ${classAttr} ${src} ${value} ${endingTag}`; 
        }
         
        return el;
    }

    static getFilterItemsByType(type, data) {
        switch(type) {
            case 'research':
                return JsModule.getResearchItemForReport(data);
            break;
            
            case 'transactions':
                return JsModule.getTransactionsItem(data);
            break;

            default:
                console.error('You must specify a type, check getFilterItemsByType parameters');
            break;
        }
    }

    static getTransactionsItem(data) {

        if(data.company == 'NovellusDx') console.log('here');
    
        let companyLogo = JsModule.validateProp(data, 'company_logo', {tag:'img', className:''}); 
        let amount = JsModule.validateProp(data, 'amount', {tag:'p', className:'title typo h4'},  (val) => { return JsModule.resolveCurrency(val, data) }); 
        let date = JsModule.validateProp(data, 'date', {tag:'p', className:'date typo p5'}, (val)=> { return JsModule.formatDate(val) }); 
        let company = JsModule.validateProp(data, 'company', {tag:'p', className:'company typo p5'}); 
        let transType = JsModule.validateProp(data, 'type', {tag:'p', className:'trans-type typo p5'}); 
        let role = JsModule.validateProp(data, 'role', {tag:'p', className:'role typo p5'}); 

        if(data.amount == 0){
            amount = '<p class="title typo h4"></p>';
        }

        transType = transType.replace("+", "&");

        let markup = `
            <div class="data-item result-item">
                <div class='lined-box'>
                    <div class='lines top'>
                        <div class='line'></div>
                        <div class='line'></div>
                        <div class='line'></div>
                    </div>
                </div>
                <div class='content transaction'>
                    ${companyLogo}
                    ${amount}                
                    
                    <div class='transaction-info-cont'>
                        ${date}
                        ${company}
                        ${transType}
                        ${role}
                    </div>
                </div>
            </div>
        `;

        return markup;
    }
    
    /**
     * getResearchItemForReport(..)
     * return the markup for a research report Item. Pass the report item into this method, get
     * the markup
     */
    static getResearchItemForReport(data) {
        let analystMarkup;
        let date = JsModule.validateProp(data, 'date', {tag:'span', className:'cmp-rre__hightlight typo p3'}, (val)=> { return JsModule.formatDate(val) }); 
        let photo = JsModule.validateProp(data, 'analyst.photo', {tag:'img', className:'cm-rre__analyst-img'}); 
        let fullName = JsModule.validateProp(data, 'analyst.full_name', {tag:'h4', className:'cm-rre__analyst-name typo h4'}); 
        let role = JsModule.validateProp(data, 'analyst.role', {tag:'h6', className:'cm-rre__analyst-role typo p3'}); 
        let author = JsModule.validateProp(data, 'author', {tag:'h4', className:'cm-rre__analyst-name'}); 
        let title = JsModule.validateProp(data, 'title', {tag:'a', className:''}); 
        let sector = JsModule.validateProp(data, 'sector', {tag:'span', className:'typo p3'}); 

        let _r = (data.rating == 'BUY') ? "cmp-rre__arrow green-arrow" : ((data.rating === 'SELL') ? "cmp-rre__arrow red-arrow" : "" );

        let rating = JsModule.validateProp(data, 'rating', {tag:'span', className:`typo p3 ${_r}`}); 
        let abstract = JsModule.validateProp(data, 'abstract', {tag:'p', className:'cmp-rre__abstract typo p3'}); 


        //notice a bug, where if the title of the report or the author's name contained a quotation mark
        //it would break the string inside the "onclick" attribute below. So just eliminating all quotation
        //marks from these two strings. These two strings are then dumped into a list and stringified to
        //later serve as the second argument for the handleRequest function
        let _titleArg = _stringOps.removeQuotes(title);
        let _authorArg = _stringOps.removeQuotes(author);
        let arg = JSON.stringify([_titleArg, _authorArg]);

        if (data.analyst) {
            analystMarkup = `
                    <div class='cm-rre__analyst'>
                        ${photo}
                        <div class='cm-rre__analyst-info'>
                            ${fullName}
                            ${role}
                        </div>
                    </div>
                `;
        } else {
            analystMarkup = author;
        }

        let markup = 
            `<div class='research-item result-item'>
                <h3 class='cmp-rre__title typo h3' onclick='javascript:window.$methods.handleRequest("research", ${arg})'>${title}</h3>
                <div class='cmp-rre__aditional-data'>
                    ${date}
                    ${sector}
                    ${rating}
                </div>
                ${abstract}
                ${analystMarkup}
            </div>`;

        return markup;
    }

    static formatDate(strDate) {

        if (!strDate) return '';

        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const splitted = strDate.substr(0, 10).split('-');

        return `${months[parseInt(splitted[1]) - 1]} ${splitted[2]}, ${splitted[0]}`
    }
    
    static resolveCurrency(amount, data) {
        let sUnit = _stringOps.getProp(data, 'currency_unit');
        let final = '';
        
        sUnit = sUnit ? sUnit.toLowerCase() : '';

        if(sUnit == '') return amount;
            
            switch(sUnit){
                case "us": final = '$'; break;
                case "gb": final = '£'; break;
                case "eur": final = '€'; break;
            }

        return `${final} ${amount} million`;
    }
};

module.exports = JsModule;