require("@babel/polyfill");
const filters = require("../custom_modules/filter");
const jsModule = require("./js/module");
let stringOps = require("../custom_modules/string-ops");

window.$methods = jsModule;
window.$methods.stringOps = stringOps;
window.$methods.filters = filters;

window.$data = {};
window.$data['lastSelecteResearchMeta'] = null;

var layputTopPos;
var formSubmited;


const breakpoints = {
    mobile: 420,
    tablet: 768,
    desktop: 1280
}

const screenSize = {
    width: window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth,
    height: window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight
}

/**
 * method to initialize the modal layer (hide it)
 */
var initModalLayer = () => {
    $('#modal-layer').css('display', 'none');
    $("#modal-layer > svg").click(closeModalLayer);
}

/**
 * method to scroll the page body to the top and slowly fade the content into view
 */
var scrollToTop = () => {
    $("html,body").css('opacity', 0);
    $("html,body").css('transition', 'opacity 0.2s ease-in');

    setTimeout(() => {
        $("html,body").scrollTop(0);
        $("html,body").css('opacity', 1);
    }, 200);
}

var freezeLayout = (freeze) => {
    let mainLayout = $("#main-layout");
    let params;

    if (freeze) {
        mainLayout.css('top', -layputTopPos);
        mainLayout.css('position', 'fixed');
    } else {
        mainLayout.css('position', 'relative');
        mainLayout.css('top', '0');
        $(window).scrollTop(layputTopPos);
    }
}

/**
 * method to show a modal with the specified class name
 * @param {string} modalClass 
 */
var showModal = (modalClass) => {
    let modals = $("#modal-layer > div");
    let modalReq = null;

    for(let i = 0; i < modals.length; i++){
        if(modals[i].className.indexOf(modalClass) >= 0){
            modalReq = modals[i];        
        }

        modals[i].style.display = 'none';
    }

    if(modalReq){
        modalReq.style.display = 'block';
        revealModalLayer();
    }
}

/**
 * method to fade in the modal layer into view
 */
var revealModalLayer = () => {
    $("#modal-layer").css('display', 'block');
    layputTopPos = $(window).scrollTop();
    freezeLayout(true);
    setTimeout(() => $("#modal-layer").css('opacity',1), 10);
}

/**
 * method to fade the modal layer out of view
 */
var closeModalLayer = () => {
    $("#modal-layer").css('opacity', 0);
    freezeLayout(false);
    setTimeout(() => $("#modal-layer").css('display','none'), 250);
}

var setContactFormSate = (val) => {
    formSubmited = val;
};

var getContactFormSate = () => {
    return formSubmited;
};

/**
 * This method handles the "contact" / "research" requests. Its very important that its in the code
 * since there will be <a href='javascript:window.$methods.handleRequest(..)'> in the markup
 * @param {string} arg - type of request
 * @param {array} meta - list of meta data (e.g. for research requests, [report title, author] is provided in meta)
 */
window.$methods.handleRequest = (arg, meta) => {

    window.$data.lastSelecteResearchMeta = meta;

    //Some times the strings in "meta" will contain html tags
    //around the actual content. e.g. <h4> Report title is here </h4>
    //we'll simply clip that out to get "Report title is here"
    if(meta) {
        if(typeof(meta) == typeof([])){
            for(let k = 0; k < meta.length; k++){
                let strItem = meta[k];
                let indexStart = strItem.indexOf(">") + 1;
                let indexEnd = strItem.lastIndexOf("</");
                meta[k] = (indexStart >= 0 && indexEnd >= 0) ? strItem.substring(indexStart, indexEnd).trim() : strItem.trim();
            }
        }

        console.log("--> research data:", meta);
    }


    //based on arg, let's show the modal
    if(arg.indexOf('research') >= 0) showModal('research', meta);
    if(arg.indexOf('contact') >= 0) showModal('contact');
};

/**
 * Method to save contact form state
 * Once the form is submited is going to be blocked during the page sesion,
 * when the page is reloaded the state is reset
 */
let contactFormState = (arg, val) => {
    if(arg.indexOf('set') >= 0) setContactFormSate(val);
    if(arg.indexOf('get') >= 0) return getContactFormSate();
}

let getNavBarHeight = function(){
    let mainNavHeight = $('.navigation.isDesktop').outerHeight();
    let stickySubMenu = ( $('.sticky-sub-menu').length )? $('.sticky-sub-menu').height() : 0;
    if(mainNavHeight > 0   && $('.navigation.isDesktop').css("display") !== 'none') {
        return mainNavHeight + stickySubMenu;
    }else{
        return $('.nav-header').outerHeight();
    }
};

let scrollToSeparator = function(top){
    console.log("===> scroll to:", top);
    setTimeout(()=>{
        $('html,body').animate({
            scrollTop: top
        }, 'slow');
    }, 1000);
    
}

let matchSeparator = function(hash){
    $('.cmp_nav_separator').each(function(){
        let string = $(this).data('label').trim().toLowerCase();
        let slug = stringOps.replaceAll(string, ' ', '_');
        
        if(slug == hash){ 
            console.log("===> slug to navigate to:", slug);
            let navBar = getNavBarHeight();
            let separatorTop = $(this).offset().top;
            let top = separatorTop - navBar;
            setTimeout( scrollToSeparator(top), 1000);   
        }
    });
};

let checkForInPageNavigation = () => {
    let hash = decodeURI(window.location.hash);
    hash = hash.replace("#", '');

    //@abarrionuevo
    //you need to check if the given hash occurs as a nav separator in this page.
    //if it does, scroll the page to that point.
    console.log("===> Page hash:", hash);

    matchSeparator(hash);
}


let deviceType = () =>{
    let device = {
        isMobile: false,
        isTablet: false,
        isDesktop: false
    }

    if(screenSize.width <= breakpoints.mobile) {
        device.isMobile = true;
    } else if (screenSize.width > breakpoints.mobile + 1 && screenSize.width <= breakpoints.tablet){
        device.isTablet = true;
    } else {
        device.isTablet = true;
    }

    return device;
}

//methods we want to make accessible to components
window.$methods.showModal = showModal;
window.$methods.hideModal = closeModalLayer;
window.$methods.contactFormState = contactFormState;
window.$methods.deviceType = deviceType;


$(document).ready(function(){
    initModalLayer();
    scrollToTop();
    checkForInPageNavigation();
});