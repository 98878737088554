
const url = "/";
const endpoints = {
    research:'filter_research',
    transactions:'filter_transactions',
    transaction:'filter_transactions',
    event:'filter_events',
    article:'filter_articles'
}

class Filter{

    static makeApiCall(endpoint, args) {
        return new Promise((resolve, reject) => {
            let _args = args ? JSON.stringify(args) : '';
        
            $.ajax({
                url: endpoint,
                type: 'POST',
                contentType: 'application/json',
                data: _args
            })
            .done(function(result) {
                resolve(result);
            })
            .fail(function(error) {
                reject(error);
            })
        });
    }

    static getData(type, args) {

        if(!type){
            console.log('Type was not defined! Please check your filter method arguments.');
        }

        let endpoint = `${window.location.origin}/${endpoints[type.toLowerCase()]}`;
        return Filter.makeApiCall(endpoint, args);
    }
}

module.exports = Filter;